import React from 'react';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InnerListItem from './InnerListItem';

import Icon from '../../Accessory/Icon';

function DrawerMenuListItem(props) {

    const [state, setState] = React.useState({
        isOpen: false,
        item:props.item,
      });
    const handleClick = () => {
        setState({...state, isOpen: !state.isOpen});
    };
    const linkAction = path => props.history.push(path);

    if (state.item.children.length > 0) {
        return (
            <div>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>
                    <Icon iconkey={state.item.iconKey} />
                </ListItemIcon>
                <ListItemText primary={state.item.title} />
                {state.isOpen ? <Icon iconkey='expandLess' /> : <Icon iconkey='expandMore' />}
                </ListItem>
                <Collapse in={state.isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                
                {state.item.children.map((_i) => {
                    return (<InnerListItem key={_i.key} item={_i} history={props.history}/>);
                })}
                </List>
            </Collapse>
            </div>);
    } else {
        return (
            <ListItem button key={state.item.key} onClick={e => linkAction(state.item.link)} >
                <ListItemIcon><Icon iconkey={state.item.iconKey}/></ListItemIcon>
                <ListItemText primary={state.item.title} />
            </ListItem>);
    }
}

export default DrawerMenuListItem;