import React from 'react';
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    screen: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        position: 'absolute',
        zIndex: 10000,
        opacity: 0.4,
    },
    text: {
        position: 'absolute',
        color: 'black',

        top: '50%',
        left: '50%',
        zIndex: 10001,
    }
}));

export default function Loading (props) {
    const classes = useStyles();
    const isLoading = props.appState.isLoading ? true : false;

    const showLoadingView = () => {
        if (isLoading) {
            return (
                <React.Fragment>
                    <div className={classes.screen}>

                    </div>
                    <div className={classes.text}>
                        Now Loading...
                    </div>
                </React.Fragment>
            );
        }
    };

    return (
      <React.Fragment>
          {showLoadingView()}
      </React.Fragment>
    );
}