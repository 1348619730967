const initialState = {
    preSchoolName: '高倉台幼稚園',


};

export default function preSchool(state = initialState, action) {
    switch (action.type) {
        // case Constants.LOGIN_AUTH:
        //     console.log('rewrite!');
        //     return Object.assign({}, state, {
        //         authUser: {
        //             username: 'ryoma@rabbit-knot.co.jp',
        //             name: '藤田竜馬',
        //             role: 'systemAdmin',
        //         },
        //         isLoggedIn: true
        //     });
        // case Constants.LOGOUT_AUTH:
        //     return Object.assign({}, state, {
        //         authUser: null,
        //     });
        // case Constants.SIGNUP_AUTH:
        //     return Object.assign({}, state, {});
        default:
            return state;
    }
}