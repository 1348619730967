import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TabPanel from '../../../../components/Common/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StudentList from './StudentListContainer';

export default class Top extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0,
        }
    }
    componentDidMount() {
    }

    handleChange(event, newValue) {
        this.setState({
          tabIndex: newValue,
      });
    }

    a11yProps(index) {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    render() {
        const classes = theme => ({

            title: {
                flexGrow: 1,
            },
            content: {
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            },
            container: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
            },
        });

        return (
            <React.Fragment>
                <AppBar position="static" color={'default'}>
                    <Tabs value={this.state.tabIndex} onChange={(e, newValue) => this.handleChange(e,newValue)} aria-label="simple tabs">
                        <Tab label={'園児一覧'} {...this.a11yProps(0)} />
                        {/*<Tab label={'保護者一覧'} {...this.a11yProps(1)} />*/}
                        {/*<Tab label={'学級一覧'} {...this.a11yProps(2)} />*/}
                    </Tabs>
                </AppBar>
                <div className={classes.content}>
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.left}>

                                <TabPanel value={this.state.tabIndex} index={0}>
                                    <StudentList {...this.props} label={'園児一覧'} />
                                </TabPanel>
                                {/*<TabPanel value={this.state.tabIndex} index={1}>*/}
                                {/*    <ShowGakkyuInformation {...props} label={title2} gakkyuId={gakkyuId} gakkyuStudents={students}/>*/}
                                {/*</TabPanel>*/}
                                {/*<TabPanel value={this.state.tabIndex} index={2}>*/}
                                {/*    <ManageGakkyuAbsence {...props} label={title3} gakkyuId={gakkyuId} gakkyuStudents={students}/>*/}
                                {/*</TabPanel>*/}
                            </Grid>
                        </Grid>


                    </Container>

                </div>
            </React.Fragment>
        )
    }
};