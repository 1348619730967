import moment from "moment";
import {store} from "./store";

export function calcUseTime(_fromTime, _selectedAt=false) {
    let now = moment();
    if (_selectedAt) {
        now = moment(_selectedAt, 'HH:mm');
    }

    //仕様注：5分超過までは切り捨ての15分刻みで記録
    const nowMinutes = now.minutes();
    const overTime = nowMinutes%15;
    let loggingTime = '';
    if (overTime > 5) {
        loggingTime = nowMinutes - overTime + 15;
    } else {
        loggingTime = nowMinutes - overTime;
    }
    now.minutes(loggingTime).second(0).millisecond(0);

    let useTimeMinutes = now.diff(moment(_fromTime, 'HH:mm:ss'), 'minutes');
    if (useTimeMinutes < 0 ) {
        useTimeMinutes = 0;
    }
    const fromTime = moment(_fromTime, 'HH:mm:ss').format('HH:mm');
    const selectedAt = now.format('HH:mm');

    return {useTimeMinutes, fromTime, selectedAt};
}

export function calcUseTimeByDefaultOrCurrent(_fromTime, _defaultToTime) {

    let _selectedAt;
    const now = moment();
    const defaultToTime = moment(_defaultToTime, 'HH:mm');
    if (now.isAfter(defaultToTime)) {
        _selectedAt = defaultToTime;
    } else {
        _selectedAt = now;
    }
    const calcResult = calcUseTime(_fromTime, _selectedAt);
    const useTimeMinutes = calcResult.useTimeMinutes;
    const selectedAt = calcResult.selectedAt;
    const fromTime = calcResult.fromTime;

    return {useTimeMinutes, fromTime, selectedAt};
}

export function buildSelectTimesFromAndTo(_fromTime, _toTime, _currentFromTime=false) {
    const fromTime = moment(_fromTime, 'HH:mm:ss');
    const toTime = moment(_toTime, 'HH:mm:ss');
    const currentFromTime = moment(_currentFromTime, 'HH:mm:ss');
    let fromSelection = [];
    let t = moment(fromTime, 'HH:mm');
    while(t.isBefore(toTime)) {
        fromSelection.push(t.clone());
        t.add(15, 'm');
    }
    let toSelection = Object.assign([], fromSelection);
    toSelection.push(t.clone());
    toSelection.shift();
    if (_currentFromTime) {
        toSelection = toSelection.filter(t=> t.isAfter(currentFromTime));
    }
    return {fromSelection, toSelection}
}

export function getExtendedDaycareScheduleByDate(_date) {
    const schedule = Object.assign([], store.getState().daySchedule.extendedDaycareSchedule);
    return schedule.find(sc => sc.date === _date);
}