import { axiosPost } from '../_helpers';
import {API_PATH, ACTION_TYPE} from "../constants";

// API Request
export function studentsLoad() {
    return (dispatch) => {
        dispatch(loadRequest());
        return axiosPost( API_PATH.loadStudents, {})
            .then(res => dispatch(loadSuccess(res)))
            .catch(err => dispatch(loadFailure(err)))
    }
}

// API Response
const loadRequest = () => {return {type: ACTION_TYPE.STUDENTS_LOAD_REQUEST}}
const loadSuccess = res => {return {type: ACTION_TYPE.STUDENTS_LOAD_FINISH, students: res.data.students}}
const loadFailure = err =>  { return {type: ACTION_TYPE.STUDENTS_LOAD_FINISH, error: err}}