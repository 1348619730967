import { axiosPost } from '../_helpers';
import {API_PATH, ACTION_TYPE} from "../constants";

export function loadExtendedDaycareRecords(studentId=null, date=null, yearMonth=null) {
    return dispatch => {
        return axiosPost(API_PATH.loadExtendedDaycareRecords, {studentId, date, yearMonth})
            .then(res => dispatch(loadExSuccess(res)))
            .catch(err => dispatch(errorExtendedDaycareRequest(err)))
    }
}

export function registerExtendedDaycareRecords(studentId, date, fromTime, toTime, status='reservation', hasAteSnack=true, hasJoinGymnastics=false, comment='') {
    return dispatch => {
        return axiosPost(API_PATH.registerExtendedDaycareRecords, {
            studentId, date, fromTime, toTime, status, hasAteSnack, hasJoinGymnastics, comment})
                .then(res => dispatch(registerExtendedDaycareSuccess(res)))
                .then(res => dispatch(switchSnackBar(res)))
                .catch(err => dispatch(errorExtendedDaycareRequest(err)));
    }
}

export function changeStatusExtendedDaycareRecords(recordId, status) {
    return dispatch => {
        return axiosPost(API_PATH.changeStatusExtendedDaycareRecords, {recordId, status})
            .then(res => dispatch(loadExSuccess(res)))
            .catch(err => dispatch(errorExtendedDaycareRequest(err)))
    }
}
export function deleteExtendedDaycareRecords(recordId) {
    return dispatch => {
        return axiosPost(API_PATH.deleteExtendedDaycareRecords, {recordId})
            .then(res => dispatch(loadExSuccess(res)))
            .catch(err => dispatch(errorExtendedDaycareRequest(err)))
    }
}
export function changeOptionsExtendedDaycareRecords(recordId, optionsKey, optionsValue) {
    return dispatch => {
        return axiosPost(API_PATH.changeOptionsExtendedDaycareRecords, {recordId, optionsKey, optionsValue})
            .then(res => dispatch(successChangeOptionsExtendedDaycareRecords(res)))
            .catch(err => dispatch(errorExtendedDaycareRequest(err)))
    }
}

export function loadBillingInfoRecordsForPersonal(studentId, yearMonth) {
    return dispatch => {
        return axiosPost(API_PATH.loadBillingInfoRecordsForPersonal, {studentId, yearMonth})
            .then(res => dispatch(loadBillingInfoRecordsForPersonalSuccess(res)))
            .catch(err => dispatch(errorExtendedDaycareRequest(err)));
    }
}

export function download() {
    return dispatch => {
        return axiosPost(API_PATH.baseURL + API_PATH.downloadVoucher)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'voucher' + this.state.yearMonth + '--' + this.state.studentId + '.xlsx';
                    a.click();
                });
                //window.location.href = response.url;
            })
            .catch();
    }
}

export function leavingExtendedDaycare(recordId, fromTime, toTime, useTimeMinutes, hasAteSnack) {
    return dispatch => {
        return axiosPost(API_PATH.leavingExtendedDaycare, {
            recordId:recordId, fromTime:fromTime, toTime:toTime, useTime:useTimeMinutes, hasAteSnack: hasAteSnack})
            .then(res => dispatch(leavingSuccess(res)))
            .catch(err => dispatch(leavingError(err)))}}

// API Response
const registerExtendedDaycareSuccess = res => {return {
    type: ACTION_TYPE.REGISTER_EXTEND_DAYCARE_FINISH,
    date: res.data.date,
    records: res.data.records}};

const switchSnackBar = res => {return {
    type: ACTION_TYPE.SNACK_BAR_IS_OPEN,
}};

const loadExSuccess = res => {return {
    type: ACTION_TYPE.LOAD_EXTEND_DAYCARE_FINISH,
    yearMonth: res.data.yearMonth,
    date: res.data.date,
    records: res.data.records}};

const successChangeOptionsExtendedDaycareRecords = res => {return {
    type: ACTION_TYPE.CHANGE_OPTIONS_EXTENDED_DAYCARE,
    date: res.data.date,
    records: res.data.records,
}};

const errorExtendedDaycareRequest = error => {return {
    type: ACTION_TYPE.ERROR_EXTENDED_DAYCARE_REQUEST,
    error: error
}};

const loadBillingInfoRecordsForPersonalSuccess = res => {return {
    type: ACTION_TYPE.LOAD_BILLING_INFO_RECORDS_FOR_PERSONAL,
    yearMonth: res.data.yearMonth,
    studentId: res.data.studentId,
    billingRecord: res.data.billingRecord,
    monthEndBilling: res.data.monthEndBilling,
    unfixedBillingRecord: res.data.unfixedBillingRecord
}};

const leavingSuccess = res => { return {
    type: ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_FINISH,
    date: res.data.date,
    records: res.data.records,
}};
const leavingError = err => { return {
    type: ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_ERROR,
    error: err
}}