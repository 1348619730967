export * from './Api';

// Auth
export const ACTION_TYPE = {
    // APPLICATION ACTION
    SWITCH_DRAWER: 'SWITCH_DRAWER',
    SNACK_BAR_IS_OPEN: 'SNACK_BAR_IS_OPEN',
    SNACK_BAR_IS_CLOSE: 'SNACK_BAR_IS_CLOSE',
    LOADING_START: 'LOADING_START',
    LOADING_FINISH: 'LOADING_FINISH',
    REFRESH_CURRENT_TIME: 'REFLESH_CURRENT_TIME',

    // LOGIN ACTION
    LOGIN_FINISH: 'LOGIN_FINISH',
    LOAD_AUTH_USER: 'LOAD_AUTH_USER',
    LOGOUT_AUTH:'LOGOUT_AUTH',
    SIGNUP_AUTH:'SIGNUP_AUTH',

    // GAKKYU ACTION
    RELOAD_GAKKYU: 'RELOAD_GAKKYU',
    LOAD_FINISH_GAKKYUS: 'LOAD_FINISH_GAKKYUS',
    LOAD_ERROR_GAKKYUS: 'LOAD_ERROR_GAKKYUS',

    // STUDENT ACTION
    STUDENTS_LOAD_FINISH: 'STUDENTS_LOAD_FINISH',
    STUDENTS_LOAD_REQUEST: 'STUDENTS_LOAD_REQUEST',

    // EXTEND DAYCARE ACTION
    ERROR_EXTENDED_DAYCARE_REQUEST: 'ERROR_EXTENDED_DAYCARE_REQUEST',
    REGISTER_EXTEND_DAYCARE_FINISH: 'REGISTER_EXTEND_DAYCARE_FINISH',
    LOAD_EXTEND_DAYCARE_FINISH: 'LOAD_EXTEND_DAYCARE_FINISH',
    CHANGE_OPTIONS_EXTENDED_DAYCARE: 'CHANGE_OPTIONS_EXTENDED_DAYCARE',
    LOAD_BILLING_INFO_RECORDS_FOR_PERSONAL: 'LOAD_BILLING_INFO_RECORDS_FOR_PERSONAL',
    LEAVING_EXTENDED_DAYCARE_FINISH: 'LEAVING_EXTENDED_DAYCARE_FINISH',
    LEAVING_EXTENDED_DAYCARE_ERROR: 'LEAVING_EXTENDED_DAYCARE_ERROR',

    // ATTENDANCE ACTION
    REGISTER_ATTENDANCE_SUCCESS: 'REGISTER_ATTENDANCE_SUCCESS',
    LOAD_ATTENDANCE_FINISH: 'LOAD_ATTENDANCE_FINISH',
    ERROR_ATTENDANCE_REQUEST: 'ERROR_ATTENDANCE_REQUEST',

    // DAY SCHEDULE
    LOAD_SCHEDULE_EXTEND_DAYCARE_FINISH: 'LOAD_SCHEDULE_EXTEND_DAYCARE_FINISH',
    LOAD_SCHEDULE_FOR_MONTH: 'LOAD_SCHEDULE_FOR_MONTH',
    LOAD_SCHEDULE_YEAR_MONTHS_BY_CATEGORY: 'LOAD_SCHEDULE_YEAR_MONTHS_BY_CATEGORY',
    ERROR_SCHEDULE_REQUEST: 'ERROR_SCHEDULE_REQUEST',
};

export const EXDAYCARE_STATUS = {
    reservation: 'reservation',
    leaving: 'leaving',
    fix: 'fix',
    invalid: 'invalid',
};

export const EXDAYCARE_PATH = [
    {index: 0, key: 'for-day'},
    {index: 1, key: 'for-register'},
    {index: 2, key: 'for-month'},
    {index: 3, key: 'for-personal'},
    {index: 4, key: 'for-calc'},
];

export const APP_INFO = {
    version: '0.5.2'
};