import {ACTION_TYPE} from "../constants";
const initialState = {
    calendar:[],
    extendedDaycareSchedule: [],
    extendedDaycareScheduleMonths: [],
    yearMonths: [],
};

export default function daySchedule(state = initialState, action) {
    switch(action.type) {
        case ACTION_TYPE.LOAD_SCHEDULE_YEAR_MONTHS_BY_CATEGORY:
            const newYearMonths = Object.assign({}, state.yearMonths);
            newYearMonths[action.category] = action.yearMonths;
            return Object.assign({}, state, {
                yearMonths: newYearMonths,
            });
        case ACTION_TYPE.LOAD_SCHEDULE_FOR_MONTH:
            return Object.assign({}, state, {
                calendar: action.calendar,
            });
        case ACTION_TYPE.LOAD_SCHEDULE_EXTEND_DAYCARE_FINISH:
            return Object.assign({}, state, {
                extendedDaycareSchedule: action.extendedDaycareSchedule,
                extendedDaycareScheduleMonths: action.extendedDaycareScheduleMonths,
            });
        case ACTION_TYPE.ERROR_SCHEDULE_REQUEST:
            return Object.assign({}, state, {});
        default:
            return state;
    }
}