import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TabPanel from '../Common/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ForDay from "../../containers/Page/ExtendedDaycare/ForDay";
import RegistarRecord from "../../containers/Page/ExtendedDaycare/RegistarRecord";
import IndividualMonthlyUsage from "../../containers/Page/ExtendedDaycare/ForPersonalBilling";
import ManageDaycareRecords from "../../containers/Page/ExtendedDaycare/ManageDaycareRecords";
import ManageMonthlySchedule from '../../containers/Page/ExtendedDaycare/ManageMonthlySchedule';
import {debug} from "../../_helpers";

const styles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function Top(props) {
    const classes = styles();

    function a11yProps(index) {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
      };
    }

    return (
        <React.Fragment>
            <AppBar position="static" color={'default'}>
                <Tabs
                    value={props.tabIndex}
                    onChange={(e, newValue) => props.handleTabChange(e, newValue)}
                    aria-label="simple tabs">

                    <Tab label="今日の預かり保育" {...a11yProps(0)} />
                    <Tab label="預かり保育登録" {...a11yProps(1)} />
                    <Tab label="月間予定" {...a11yProps(2)} />
                    <Tab label="個別利用状況" {...a11yProps(3)} />
                    {/*<Tab label="日別一覧管理" {...a11yProps(4)} />*/}
                </Tabs>
            </AppBar>
            <div key='extend-daycare' className={classes.content}>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.left}>

                            <TabPanel value={props.tabIndex} index={0}>
                                <ForDay {...props} tabKey={props.tabKey}/>
                            </TabPanel>
                            <TabPanel value={props.tabIndex} index={1}>
                                <RegistarRecord {...props}/>
                            </TabPanel>
                            <TabPanel value={props.tabIndex} index={2}>
                                <ManageMonthlySchedule {...props}/>
                            </TabPanel>
                            <TabPanel value={props.tabIndex} index={3}>
                                <IndividualMonthlyUsage {...props} tabSubParam1={props.tabSubParam1} tabSubParam2={props.tabSubParam2}/>
                            </TabPanel>
                            {/*<TabPanel value={props.tabIndex} index={4}>*/}
                            {/*    調整中*/}
                            {/*    /!*<ManageDaycareRecords {...props}/>*!/*/}
                            {/*</TabPanel>*/}
                        </Grid>
                    </Grid>


                </Container>

            </div>
        </React.Fragment>
    )
};