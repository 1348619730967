import React from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Title from '../../../components/Text/Title';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Icon from "../../../components/Accessory/Icon";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  rowHeader: {
    // backgroundColor: green[300],
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  sidePaddingZero: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  listCell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  gakkyuNameCell: {
    width: '70px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nameCell: {
    width: '215px',
    fontSize: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  timeCell: {
    width: '110px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  optionCell: {
    width: '80px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  commentArea: {
    maxWidth: '50%',
    minWidth: '150px'
  },
  iconCell: {
    textAlign: 'center',
    width: '100px',
  },
  selectBoxForPickupDate: {
    width: '180px',
  },
  inputCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function ListRecordsForDay(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const daycareRecords = props.extendedDaycare.extendedDaycareRecords;
  const extendedDaycareScheduleMonths = props.daySchedule.extendedDaycareScheduleMonths;
  const currentRecords = daycareRecords[props.currentSelectedDate] ? daycareRecords[props.currentSelectedDate]:[];

  const recordDelete = (e, row) => {
    props.deleteExtendedDaycareRecords(row.id);
  };

  const recordChangeStatus = (e, row) => {
    props.changeStatusExtendedDaycareRecords(row.id, 'reservation');
  };

  const handleChangeHasSnack = (e, row) => {
    props.changeOptionsExtendedDaycareRecords(row.id, 'hasAteSnack', !row.hasAteSnack);
  };

  if (props.extendedDaycareSchedule.length > 0) {

    return (
        <main className={props.contentClass}>
          <div className={props.toolbarClass}/>
          <Container maxWidth="lg" className={classes.sidePaddingZero}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Title>
                  <FormControl className={classes.formControl}>
                    <Select
                        native
                        defaultValue={moment(props.currentSelectedDate, 'YYYY-MM-DD').format('YYYY-MM')}
                        onChange={e => props.handleSelectMonth(e.target.value)}
                        inputProps={{
                          name:"yearMonth",
                          id:"outlined-yearMonth-native-simple",
                        }}
                    >
                      {extendedDaycareScheduleMonths.map(exd => {
                      {['2019-09','2019-10','2019-11','2019-12','2020-01','2020-02','2020-03'].map(exd => {
                        return (<option key={exd+'-dateSchedule'} value={exd}
                                        selected={exd===moment(props.currentSelectedDate, 'YYYY-MM-DD').format('YYYY-MM')}>
                          {moment(exd, 'YYYY-MM').format('YYYY年MM月')}
                        </option>)
                      })}})}
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <Select
                        native
                        defaultValue={props.currentSelectedDate}
                        onChange={e=>props.handleSelectDay(e.target.value)}
                        inputProps={{
                          name:"date",
                          id:"outlined-date-native-simple",
                        }}
                    >
                      {props.extendedDaycareSchedule.map(exd => {
                        return (<option key={exd.id+'-dateSchedule'} value={exd.date} selected={exd.date===props.currentSelectedDate}>
                          {moment(exd.date, 'YYYY-MM-DD').format('DD日(ddd)')}
                        </option>)
                      })}
                    </Select>
                  </FormControl>
                  の預かり保育</Title>

                <Paper className={fixedHeightPaper}>
                  <Title>申請人数 {currentRecords.length} 名</Title>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.gakkyuNameCell}>くみ</TableCell>
                        <TableCell className={classes.nameCell}>なまえ</TableCell>
                        <TableCell className={classes.timeCell}>保育時間</TableCell>
                        <TableCell className={classes.optionCell}>おやつ</TableCell>
                        <TableCell className={classes.optionCell}>課外体育</TableCell>
                        <TableCell className={classes.commentArea}>備考</TableCell>
                        <TableCell className={classes.iconCell}>降園</TableCell>
                        <TableCell className={classes.iconCell}>削除</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRecords.filter(r => r.status == 'reservation').map(row => (
                          <TableRow key={row.id + '-extends-res'} className={classes.studentRow}>
                            <TableCell className={classes.gakkyuNameCell}>{row.gakkyuNameKana}</TableCell>
                            <TableCell className={classes.nameCell}>{row.studentNameKana}</TableCell>
                            <TableCell className={classes.timeCell}>{moment(row.fromTime,'HH:mm:ss').format('HH:mm')}~{moment(row.toTime,'HH:mm:ss').format('HH:mm')}</TableCell>
                            <TableCell className={classes.optionCell}>
                              <Checkbox className={classes.inputCheckbox} checked={row.hasAteSnack ? row.hasAteSnack : false} onChange={e => handleChangeHasSnack(e, row)} value={'hasAteSnack'} />
                            </TableCell>
                            <TableCell className={classes.optionCell}>
                              {row.hasJoinGymnastics ? <Icon color={'secondary'} iconkey={'accessibilityNew'}/> : ''}
                            </TableCell>
                            <TableCell className={classes.commentArea}>
                              {row.comment}
                            </TableCell>
                            <TableCell className={classes.iconCell}>
                              <Button
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  color="default"
                                  onClick={e => props.handleLeaving(e, row.id)}>
                                <Icon color={'action'} iconkey={'exitToApp'}/>
                              </Button>
                            </TableCell>
                            <TableCell className={classes.iconCell}>
                              <Button
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  color="default"
                                  onClick={e => recordDelete(e, row)}>
                                <Icon color={'action'} iconkey={'delete'}/>
                              </Button>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
                <Paper className={fixedHeightPaper}>
                  <Title>降園した子</Title>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.gakkyuNameCell}>くみ</TableCell>
                        <TableCell className={classes.nameCell}>なまえ</TableCell>
                        <TableCell className={classes.timeCell}>保育時間</TableCell>
                        <TableCell className={classes.optionCell}>利用時間(分)</TableCell>
                        <TableCell className={classes.optionCell}>おやつ</TableCell>
                        <TableCell className={classes.optionCell}>課外体育</TableCell>
                        <TableCell className={classes.optionCell}>料金</TableCell>
                        <TableCell className={classes.commentArea}>備考</TableCell>
                        <TableCell className={classes.iconCell}>修正</TableCell>
                        <TableCell className={classes.iconCell}>降園取消</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentRecords.filter(r => r.status != 'reservation').map(row => {
                        const snackCharge = row.hasAteSnack ? 100 : 0;//TODO:おかし料金
                        const useAmount = (row.useTime / 15) *50 + snackCharge;
                        return (
                            <TableRow key={row.id + '-extends-nores'}>
                              <TableCell className={classes.gakkyuNameCell}>{row.gakkyuNameKana}</TableCell>
                              <TableCell className={classes.nameCell}>{row.studentNameKana}</TableCell>
                              <TableCell className={classes.timeCell}>{moment(row.fromTime,'HH:mm:ss').format('HH:mm')}~{moment(row.toTime,'HH:mm:ss').format('HH:mm')}</TableCell>
                              <TableCell className={classes.optionCell}>
                                {row.useTime}
                              </TableCell>
                              <TableCell className={classes.optionCell}>
                                <Checkbox checked={row.hasAteSnack} onChange={e => handleChangeHasSnack(e, row)} value={'hasAteSnack'} />
                              </TableCell>
                              <TableCell className={classes.optionCell}>
                                {row.hasJoinGymnastics ? <Icon color={'secondary'} iconkey={'accessibilityNew'}/> : ''}
                              </TableCell>
                              <TableCell className={classes.optionCell}>
                                {useAmount}
                              </TableCell>
                              <TableCell className={classes.commentArea}>
                                {row.comment}
                              </TableCell>
                              <TableCell className={classes.iconCell}>
                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    color="default"
                                    onClick={e => props.handleLeaving(e, row.id)}>
                                  <Icon color={'action'} iconkey={'edit'}/>
                                </Button>
                              </TableCell>
                              <TableCell className={classes.iconCell}>
                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    color="default"
                                    onClick={e => recordChangeStatus(e, row)}>
                                  <Icon color={'error'} iconkey={'history'}/>
                                </Button></TableCell>
                            </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </main>
    );
  } else {
    return (
        <React.Fragment>
          {'預かり保育のスケジュールがありません'}
        </React.Fragment>
    );
  }
}