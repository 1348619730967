import React from 'react';
import PropTypes from 'prop-types';

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.any.isRequired,
value: PropTypes.any.isRequired,
};

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
      component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    );
  }
