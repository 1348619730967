import React from 'react';
import moment from 'moment';
import GridListTile from '@material-ui/core/GridListTile';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import GridList from "@material-ui/core/GridList";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DateExChildCareStudentsDialog from '../../../components/ExtendedDaycare/Monthly/DateExChildCareStudentsDialog';
import {store, debug} from "../../../_helpers";
import {loadExtendedDaycareRecords} from "../../../actions";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {API_PATH} from "../../../constants";

export default class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openDayDialog: false,
            currentSelectedDate: this.props.currentSelectedDate,
        };
    }

    componentDidMount() {
        this.loadData(moment(this.props.currentSelectedDate,'YYYY-MM-DD').format('YYYY-MM'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        debug('ManageMonthlySchedule Component did update');
        let newState = {};
        if (prevProps.currentSelectedDate !== this.props.currentSelectedDate) {
            this.loadData(moment(this.props.currentSelectedDate,'YYYY-MM-DD').format('YYYY-MM'));
            newState.currentSelectedDate = this.props.currentSelectedDate;
        }
        // if (this.props.daySchedule.calendar.length > 0
        //     && this.props.daySchedule.calendar[0].date !== this.currentYearMonth()) {
        //
        // }

        if (0 !== Object.keys(newState).length) {
            this.setState(newState);
        }
    }

    loadData(yearMonth) {
        store.dispatch(loadExtendedDaycareRecords(null,null, yearMonth));
    }

    currentYearMonth() {
        return moment(this.state.currentSelectedDate, 'YYYY-MM-DD').format('YYYY-MM');
    }

    buildCalendar(dayScheduleForAMonth) {
        let extendedDaycareSchedule = this.props.daySchedule.extendedDaycareSchedule;

        if (dayScheduleForAMonth.length > 0 && extendedDaycareSchedule.length > 0) {
            let calendar = [];
            const uuid = require('uuid');
            const cardStyle = { margin: 1 };

            Array.apply(null, {length: moment(dayScheduleForAMonth[0].date, 'YYYY-MM-DD').day()}).map(Number.call, Number).forEach(d =>{
                calendar.push(<GridListTile key={uuid()}><Card /></GridListTile>);
            });
            dayScheduleForAMonth.forEach(d =>{
                const hasExtendedDaycare = (d.status === 'weekday') ? (<Typography variant={'caption'}>通常保育</Typography>) : ('');
                const day = moment(d.date, 'YYYY-MM-DD');
                const extended = () => {
                    const ex = extendedDaycareSchedule.find(es => es.date === d.date);
                    if (ex) {
                        return (<Typography variant={'caption'}>{moment(ex.fromTime, 'HH:mm:ss').format('HH:mm') + '-' + moment(ex.toTime,'HH:mm:ss').format('HH:mm')}</Typography>);
                    }
                };
                calendar.push(
                    <GridListTile key={day.format('YYYY-MM-DD')+'calTile'}>
                        <Card style={cardStyle}>
                            <CardContent onClick={e => this.handleClickDayOpen(e, day)} value={day.format('YYYY-MM-DD')}>
                                <Typography>{day.format('D')}</Typography>
                                {hasExtendedDaycare}<br/>
                                {extended()}
                            </CardContent>
                        </Card>
                    </GridListTile>
                );
            });
            return calendar;
        }
    }

    handleDayClose() {
        this.setState({
            openDayDialog: false,
        });
    }

    handleClickDayOpen(e, day) {
        this.props.setCurrentSelectedDate(day.format('YYYY-MM-DD'));
        this.setState({
            openDayDialog: true,
        })
    }

    handleChangeMonth(e) {
        this.props.setCurrentSelectedDate(moment(e.target.value, 'YYYY-MM-DD').format('YYYY-MM') + '-01');
    }

    downloadAggregateWeekdayRecordsForAMonth(e) {
        //TODO: 無理矢理発行
        // alert('月別集計を発行')
        const token = localStorage.getItem('jwt');

        let data = [{key:'yearMonth', value:''+this.currentYearMonth()}];
        let url_string = API_PATH.baseURL + API_PATH.downLoadAggregateForWeekday;
        let url_obj = new URL(url_string);
        let params = new URLSearchParams(); // もしくは url_obj.searchParams
        data.map(d => params.append(d.key, d.value));
        url_obj.search = params.toString();

        fetch(url_obj.toString(),
            { method: 'GET', headers: new Headers([["authorization", token]])})
            .then(response => response.blob()).then(blob => {
            let anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = 'month-yoshiki3-1-' + this.currentYearMonth() + '.xlsx';
            anchor.click();
        })
    };

    render() {
        const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
        const cardStyle = { margin: 1 };

        const dayScheduleForAMonth = this.props.daySchedule.calendar;
        const extendedDaycareSchedule = this.props.daySchedule.extendedDaycareSchedule;
        const extendedDaycareScheduleMonths = this.props.daySchedule.extendedDaycareScheduleMonths;
        const extendedDaycareRecord = this.props.extendedDaycare.extendedDaycareRecords;
        const currentYearMonthRecords = extendedDaycareRecord[this.currentYearMonth()];
        const nobeRiyouNinzuu = currentYearMonthRecords ? currentYearMonthRecords.length : 0;
        let useTimeSum = 0;
        if (currentYearMonthRecords) {
            extendedDaycareSchedule.forEach(day => {
                const records = currentYearMonthRecords.filter(d => d.extendsDate === day.date);
                let dayUseTime = 0;
                records.forEach(r => {
                    const _use = moment(r.toTime, 'HH:mm:ss').diff(moment(r.fromTime, 'HH:mm:ss'), 'minutes');
                    if (_use > dayUseTime) {
                        dayUseTime = _use;
                    }
                });
                useTimeSum += dayUseTime;
            });
        }

        return (
            <React.Fragment>
                <Paper>
                    <Select
                        native
                        defaultValue={this.currentYearMonth()}
                        onChange={e => this.handleChangeMonth(e)}
                        input={
                            <OutlinedInput name="month"  id="outlined-month-native-simple" />
                        }
                    >
                        {extendedDaycareScheduleMonths.map(_t => {
                            const t = moment(_t, 'YYYY-MM');
                            return (<option key={'from-'+ t.format('YYYYMM') +'-month'}
                                            value={t.format('YYYY-MM')}
                                            selected={t.format('YYYY-MM') === this.currentYearMonth()}
                            >{t.format('YYYY年MM月')}</option>)
                        })}
                    </Select>
                    <Button variant="contained" color="primary" onClick={e=>this.downloadAggregateWeekdayRecordsForAMonth(e)}>
                        月別集計表（平日用）を出力
                    </Button>
                </Paper>

                <Paper>
                    <Table size={'large'}>
                        <TableBody>
                            <TableRow>
                                <TableCell>通常保育日日数</TableCell>
                                <TableCell>預かり保育実施日数</TableCell>
                                <TableCell>預かり保育利用時間数</TableCell>
                                <TableCell>預かり保育利用園児数</TableCell>
                                <TableCell>担当教職員数</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{dayScheduleForAMonth.filter(d=>d.status === 'weekday').length}</TableCell>
                                <TableCell>{extendedDaycareSchedule.length}</TableCell>
                                <TableCell>{useTimeSum}</TableCell>
                                <TableCell>{nobeRiyouNinzuu}</TableCell>
                                <TableCell>{}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                <Paper>
                    <GridList cols={7} cellHeight='auto'>
                        {
                            weekdays.map((weekDay)=>{
                                const styles = {};
                                if(weekDay === '日'){styles.color = 'red'}
                                if(weekDay === '土'){styles.color = 'blue'}
                                return <GridListTile key={weekDay}>
                                    <Card style={cardStyle}>
                                        <CardContent>
                                            <Typography style={styles}>{weekDay}</Typography>
                                        </CardContent>
                                    </Card>
                                </GridListTile>
                            })
                        }
                        {this.buildCalendar(dayScheduleForAMonth)}
                    </GridList>
                    <DateExChildCareStudentsDialog open={this.state.openDayDialog} handleClose={e => this.handleDayClose(e)} currentDay={this.currentDay}/>
                </Paper>
            </React.Fragment>
        );
    }
}