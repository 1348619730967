import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { logoutAuth } from '../../actions/Auth';
import { switchDrawer } from '../../actions/AppState';
import Icon from '../Accessory/Icon';
import Chip from '@material-ui/core/Chip';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {APP_INFO} from "../../constants";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
}));

function NavBar(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  const linkTo = (path) => {
    props.history.push(path);
  };

  return (
    <div className={classes.root}>
      <AppBar position={props.position} className={classes.appBar} elevation={1}>
        <Toolbar>
          <IconButton onClick={props.actions.switchDrawer} edge="start" className={classes.menuButton} color={'default'} aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {'OTAMAJAKUSHI '}
            <Chip
              size="small"
              label={' ver.' + APP_INFO.version + 'b'}
              color={'default'}
            />
            {/* {props.title}{isLoggedIn ? props.auth.authUser.name : ''} */}
          </Typography>
          <div>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="default"
              onClick={handleClick}
            >
              <Icon iconkey={'settings'}/>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/*<StyledMenuItem onClick={e => linkTo('/manage/teachers')}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <Icon iconkey={'permContactCalendar'}/>*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="職員管理" />*/}
              {/*</StyledMenuItem>*/}
              {/*<StyledMenuItem onClick={e => linkTo('/manage/parents-and-children')}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <Icon iconkey={'supervisorAccount'}/>*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="学籍管理" />*/}
              {/*</StyledMenuItem>*/}
              {/*<StyledMenuItem>*/}
              {/*  <ListItemIcon>*/}
              {/*    <Icon iconkey={'dateRange'}/>*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="スケジュール入力" />*/}
              {/*</StyledMenuItem>*/}
              <StyledMenuItem onClick={props.actions.logoutAuth}>
                <ListItemIcon>
                  <Icon iconkey={'exitToApp'}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default connect(
  state => state,
  dispatch => ({
    actions: {
      logoutAuth: () => dispatch(logoutAuth()),
      switchDrawer: () => dispatch(switchDrawer()),
    }
  })
)(NavBar);