import React from 'react';
import { Route, Switch, Router , Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './containers/Auth/Login';
import Signup from './containers/Auth/Signup';
import Manage from './containers/Manage';
import { PrivateRoute } from './containers/Auth/PrivateRoute';
import {history} from './_helpers';
import * as Actions from "./actions";
import {bindActionCreators} from "redux";
import Loading from "./components/Common/Loading";



function App (props) {
    history.listen((location, action) => {
    });
    if (!props.auth.authUser && props.auth.token) {
        props.loadAuthUser(props.auth.token);
    }

    return (
        <Router history={history}>
            <Loading {...props} />
            <Switch>
                <Route exact path="/" render={({ match })=>(<Redirect to={`/manage`}/>)}/>
                <Route exact path='/login' component={Login} />
                <Route exact path='/signup' component={Signup} />
                <Switch>
                    <PrivateRoute {...props} path='/manage' component={Manage} />
                    <PrivateRoute {...props} path='/setting' component={Manage} />
                </Switch>
            </Switch>
        </Router>
    );
  }

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(Actions, dispatch)
    };
}

export default connect(
    state => state,
    mapDispatchToProps
)(App);