import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';


export default function AddTeacher(props) {

    console.log(props.addTeacherDialogIsOpen);

    const handleClose = event => {

    }
    const handleRegister = event => {

    }

    return (
      <React.Fragment>
          <Dialog open={props.addTeacherDialogIsOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">職員追加手続き</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      追加する職員のメールアドレスを入力してください。入力されたメールアドレスに登録手続きの案内を送信します。
                  </DialogContentText>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Email Address"
                      type="email"
                      fullWidth
                  />
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={handleRegister} color="primary">
                      登録する
                  </Button>
              </DialogActions>
          </Dialog>
      </React.Fragment>
    );
}

