import React from 'react';
import moment from 'moment';
import {getStudentsByGakkyuId, store} from '../../../_helpers';
import AddExtendDaycareStudent from "../../../components/ExtendedDaycare/Registar/AddExtendDaycareStudent";
import {EXDAYCARE_STATUS} from "../../../constants";
import {getExtendedDaycareScheduleByDate} from "../../../_helpers/extendedDaycare";
import {loadScheduleExtendedDaycare} from "../../../actions";

export default class RegistarRecord extends React.Component {
    constructor(props) {
        super(props);

        const gakkyus = this.props.appState.gakkyu;
        const students = this.props.students.students;
        const extendedDaycareSchedule = this.props.daySchedule.extendedDaycareSchedule.filter(
            eds => moment(eds.date, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day'))[0];

        this.state = {
            date: this.props.currentSelectedDate,
            gakurei: gakkyus[0].gakurei,
            gakkyuId: gakkyus[0].id,
            studentId: students.length > 0 ? getStudentsByGakkyuId(gakkyus[0].id)[0].id : null,
            initialFromTime: extendedDaycareSchedule ? moment(extendedDaycareSchedule.fromTime, 'HH:mm:ss').format('HH:mm') : '14:30',
            initialToTime: extendedDaycareSchedule ? moment(extendedDaycareSchedule.toTime, 'HH:mm:ss').format('HH:mm') : '19:00',
            fromTime: extendedDaycareSchedule ? moment(extendedDaycareSchedule.fromTime, 'HH:mm:ss').format('HH:mm') : '14:30',
            toTime: extendedDaycareSchedule ? moment(extendedDaycareSchedule.toTime, 'HH:mm:ss').format('HH:mm') : '19:00',
            hasAteSnack: true,
            hasJoinGymnastics: false,
            enableHasJoinGymnastics: extendedDaycareSchedule && extendedDaycareSchedule.options ? extendedDaycareSchedule.options.hasGymnastics : false,
            comment: '',
            extendedDaycareSchedule: props.daySchedule.extendedDaycareSchedule
        };
    }


    componentDidMount() {
    }

    registerExtendedDaycare() {
        this.props.registerExtendedDaycareRecords(
            this.state.studentId,
            this.state.date,
            this.state.fromTime,
            this.state.toTime,
            EXDAYCARE_STATUS.reservation,
            this.state.hasAteSnack,
            this.state.hasJoinGymnastics,
            this.state.comment
        );
    };

    handleChange(event, name) {
        if (name === 'month') {
            store.dispatch(loadScheduleExtendedDaycare(event.target.value));
        } else if (name === 'date') {
            const exSchedule = getExtendedDaycareScheduleByDate(event.target.value);
            this.setState({
                ...this.state,
                [name]: event.target.value,
                initialFromTime: moment(exSchedule.fromTime, 'HH:mm:ss').format('HH:mm'),
                initialToTime: moment(exSchedule.toTime, 'HH:mm:ss').format('HH:mm'),
                fromTime: moment(exSchedule.fromTime, 'HH:mm:ss').format('HH:mm'),
                toTime: moment(exSchedule.toTime, 'HH:mm:ss').format('HH:mm'),
                enableHasJoinGymnastics: exSchedule.options.hasGymnastics,
                hasJoinGymnastics: false,
            })
        } else if (name === 'gakkyuId') {
            const stId = getStudentsByGakkyuId(event.target.value)[0].id;
            this.setState({
                ...this.state,
                [name]: event.target.value,
                studentId: stId,
            })
        } else if (name === 'hasAteSnack') {
            this.setState({
                ...this.state,
                [name]: !this.state[name],
            })
        } else if (name === 'hasJoinGymnastics') {
            this.setState({
                ...this.state,
                hasJoinGymnastics: !this.state.hasJoinGymnastics,
                fromTime: this.state.hasJoinGymnastics ? this.state.fromTime : '15:30',
            })
        } else {
            this.setState({
                ...this.state,
                [name]: event.target.value,
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <AddExtendDaycareStudent
                    {...this.props}
                    state={this.state}
                    extendedDaycareSchedule={this.state.extendedDaycareSchedule}
                    handleChange={(e,name)=>this.handleChange(e,name)}
                    registerExtendedDaycare={(e)=>this.registerExtendedDaycare(e)}/>
            </React.Fragment>
        );
    }
}