import React from 'react';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {store, empty, debug} from "../../../_helpers";

import ListGakkyuExtendedDaycareRecordsForDay from "../../../components/Gakkyu/ListGakkyuExtendedDaycareRecordsForDay";
import {loadAttendanceRecordsByGakkyuId} from "../../../actions";

class ShowGakkyuInformation extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {};

  componentDidMount() {
    debug('Show gakkyu information');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }


  render() {
    return (
        <React.Fragment>

          <ListGakkyuExtendedDaycareRecordsForDay {...this.props}/>

        </React.Fragment>
    );
  }
}

export default withRouter(ShowGakkyuInformation);