import { axiosPost } from '../_helpers';
import {API_PATH, ACTION_TYPE} from "../constants";

export function loadAttendanceRecordsByGakkyuId(gakkyuId=null, date=null, yearMonth=null, status=null) {
    return dispatch => {
        return axiosPost(API_PATH.loadAttendanceRecordsByGakkyuId, {gakkyuId, date, yearMonth, status})
            .then(res => dispatch(loadAttendanceSuccess(res)))
            .catch(err => dispatch(errorAttendanceRequest(err)))
    }
}

export function registerAttendanceRecords(studentId, date, fromTime, toTime, status='reservation', hasAteSnack=true, hasJoinGymnastics=false, comment='') {
    return dispatch => {
        return axiosPost(API_PATH.registerAttendanceRecords, {
            studentId, date, fromTime, toTime, status, hasAteSnack, hasJoinGymnastics, comment})
                .then(res => dispatch(registerAttendanceSuccess(res)))
                .then(res => dispatch(switchSnackBar(res)))
                .catch(err => dispatch(errorAttendanceRequest(err)));
    }
}

export function changeStatusAttendanceRecords(recordId, status) {
    return dispatch => {
        return axiosPost(API_PATH.changeStatusAttendanceRecords, {recordId, status})
            .then(res => dispatch(loadAttendanceSuccess(res)))
            .catch(err => dispatch(errorAttendanceRequest(err)))
    }
}
export function deleteAttendanceRecords(recordId) {
    return dispatch => {
        return axiosPost(API_PATH.deleteAttendanceRecords, {recordId})
            .then(res => dispatch(loadAttendanceSuccess(res)))
            .catch(err => dispatch(errorAttendanceRequest(err)))
    }
}
// export function changeOptionsAttendanceRecords(recordId, optionsKey, optionsValue) {
//     return dispatch => {
//         return axiosPost(API_PATH.changeOptionsExtendedDaycareRecords, {recordId, optionsKey, optionsValue})
//             .then(res => dispatch(successChangeOptionsAttendanceRecords(res)))
//             .catch(err => dispatch(errorAttendanceRequest(err)))
//     }
// }

// API Response
const registerAttendanceSuccess = res => {return {
    type: ACTION_TYPE.REGISTER_ATTENDANCE_SUCCESS,
    date: res.data.date,
    records: res.data.records}};

const switchSnackBar = res => {return {
    type: ACTION_TYPE.SNACK_BAR_IS_OPEN,
}};

const loadAttendanceSuccess = res => {return {
    type: ACTION_TYPE.LOAD_ATTENDANCE_FINISH,
    yearMonth: res.data.yearMonth,
    date: res.data.date,
    records: res.data.records}};
const errorAttendanceRequest = error => {return {
    type: ACTION_TYPE.ERROR_ATTENDANCE_REQUEST,
    error: error
}};

//
// const successChangeOptionsAttendanceRecords = res => {return {
//     type: ACTION_TYPE.CHANGE_OPTIONS_EXTENDED_DAYCARE,
//     date: res.data.date,
//     records: res.data.records,
// }};