import {ACTION_TYPE} from "../constants";
const initialState = {
    extendedDaycareRecords:[],
    billingInfoRecordsForPersonal: [],
};

export default function extendedDaycare(state = initialState, action) {
    switch(action.type) {
        case ACTION_TYPE.REGISTER_EXTEND_DAYCARE_FINISH:
        case ACTION_TYPE.CHANGE_OPTIONS_EXTENDED_DAYCARE:
        case ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_FINISH:
        case ACTION_TYPE.LOAD_EXTEND_DAYCARE_FINISH:
            const newRecords = Object.assign({}, state.extendedDaycareRecords);
            if (action.date) {
                newRecords[action.date] = action.records;
            } else if (action.yearMonth) {
                newRecords[action.yearMonth] = action.records;
            }
            return Object.assign({}, state, {
                extendedDaycareRecords: newRecords
            });
        case ACTION_TYPE.LOAD_BILLING_INFO_RECORDS_FOR_PERSONAL:

            const billRecords = Object.assign({}, state.billingInfoRecordsForPersonal);
            billRecords[action.yearMonth+'--'+action.studentId]= {
                yearMonth: action.yearMonth,
                studentId: action.studentId,
                billingRecords:action.billingRecord,
                monthEndBilling: action.monthEndBilling,
                unfixedBillingRecord: action.unfixedBillingRecord,
            };
            return Object.assign({}, state, {
                billingInfoRecordsForPersonal: billRecords,
            });
        case ACTION_TYPE.ERROR_EXTENDED_DAYCARE_REQUEST:
            return Object.assign({}, state, {});
        default:
            return state;
    }
}