import React from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import AccessTime from '@material-ui/icons/AccessTime';
import Accessibility from '@material-ui/icons/Accessibility';
import AudioTrack from '@material-ui/icons/Audiotrack';
import AccountBox from '@material-ui/icons/AccountBox';
import AccessibilityNew from '@material-ui/icons/AccessibilityNew';
import Announcement from '@material-ui/icons/Announcement';
import Cancel from '@material-ui/icons/Cancel';
import ChevronRight from '@material-ui/icons/ChevronRight'
import Contacts from '@material-ui/icons/Contacts';
import Delete from '@material-ui/icons/Delete';
import DateRange from '@material-ui/icons/DateRange';
import Edit from '@material-ui/icons/Edit';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Home from '@material-ui/icons/Home';
import History from '@material-ui/icons/History';
import Inbox from '@material-ui/icons/Inbox';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Mail from '@material-ui/icons/Mail';
import MenuBook from '@material-ui/icons/MenuBook';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import People from '@material-ui/icons/People';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import Settings from '@material-ui/icons/Settings';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';

export default function Icon(props) {
    const iconKey = props.iconkey;

    switch(iconKey){
        case 'addCircle': return(<AddCircle {...props} />);
        case 'accessTime': return(<AccessTime {...props} />);
        case 'accountBox': return (<AccountBox {...props} />);
        case 'audioTrack': return (<AudioTrack {...props} />);
        case 'accessibility': return (<Accessibility {...props} />);
        case 'chevronRight': return (<ChevronRight {...props} />);
        case 'contacts': return (<Contacts {...props} />);
        case 'expandMore': return (<ExpandMore {...props} />);
        case 'expandLess': return (<ExpandLess {...props} />);
        case 'inbox': return (<Inbox {...props} />);
        case 'edit': return (<Edit {...props} />);
        case 'keyboardArrowRight': return (<KeyboardArrowRight {...props} />);
        case 'keyboardArrowLeft': return (<KeyboardArrowLeft {...props} />);
        case 'keyboardArrowUp': return (<KeyboardArrowUp {...props} />);
        case 'keyboardArrowDown': return (<KeyboardArrowDown {...props} />);
        case 'libraryBooks': return (<LibraryBooks {...props} />);
        case 'mail': return (<Mail {...props} />);
        case 'menuBook': return (<MenuBook {...props} />);
        case 'moveToInbox': return (<MoveToInbox {...props} />);
        case 'home': return (<Home {...props} />);
        case 'settings': return (<Settings {...props} />);
        case 'exitToApp': return (<ExitToApp {...props} />);
        case 'dateRange': return (<DateRange {...props} />);
        case 'people': return (<People {...props}/>);
        case 'permContactCalendar': return(<PermContactCalendar {...props} />);
        case 'supervisorAccount': return(<SupervisorAccount {...props} />);
        case 'delete': return (<Delete {...props} />);
        case 'cancel': return (<Cancel {...props} />);
        case 'history': return (<History {...props} />);
        case 'accessibilityNew': return (<AccessibilityNew {...props} />);
        case 'announcement': return (<Announcement {...props} />);
        default: return (<Inbox {...props} />);
    }
}
