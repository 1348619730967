import moment from 'moment';
import {ACTION_TYPE} from '../constants';
import {createMenuItem} from "../_helpers/appState";

moment.locale("ja", {
    weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

function createGakunen( id, schoolId, gakurei, gakunenName, gakunenNameKana ) {
    return { id, schoolId, gakurei,gakunenName, gakunenNameKana  };
}

const schoolId = '28107001';

const initialState = {
    openDrawer: true,
    isLoading: false,
    snackBarIsOpen: false,
    basicMenu: [
        createMenuItem('dashboard','TOP','home','/manage',1,[]),
        createMenuItem('gakkyu', '学級', 'people', '', 2, []),
        // createMenuItem('attendance','出席簿','libraryBooks','/manage/attendance',3,[]),
        createMenuItem('extend-daycare','預かり保育','accessTime','/manage/extend-daycare',4,[]),
        // createMenuItem('notification','お知らせ','announcement','/manage/notification',5,[]),
        // createMenuItem('diary','保育日誌','menuBook','/manage/Diary',6,[]),
    ],
    extendMenu: [
    ],
    gakunen: [
        createGakunen( 1, schoolId, 6, '年長', 'ねんちょう' ),
        createGakunen( 2, schoolId, 5, '年中', 'ねんちゅう' ),
        createGakunen( 3, schoolId, 4, '年少', 'ねんしょう' ),
        createGakunen( 4, schoolId, 3, '満3歳児', 'まんさんさいじ' ),
    ],
    gakkyu:[],
};

export default function appState(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.LOADING_START:
            return Object.assign({}, state, {
                isLoading: true,
            });
        case ACTION_TYPE.LOADING_FINISH:
            return Object.assign({}, state, {
                isLoading: false,
            });
        case ACTION_TYPE.SWITCH_DRAWER:
            return Object.assign({}, state, {
                openDrawer: !state.openDrawer,
            });
        case ACTION_TYPE.SNACK_BAR_IS_OPEN:
            return Object.assign({}, state, {
                snackBarIsOpen: true,
            });
        case ACTION_TYPE.SNACK_BAR_IS_CLOSE:
            return Object.assign({}, state, {
                snackBarIsOpen: false,
            });
        case ACTION_TYPE.LOAD_FINISH_GAKKYUS:
            return Object.assign({}, state, {
                gakkyu: action.gakkyu,
                basicMenu: action.basicMenu,
            });
        case ACTION_TYPE.LOAD_ERROR_GAKKYUS:
        default:
            return state;
    }
}