import { store } from './store';

export function getGakkyuByGakurei(gakurei) {
    const gakkyu = Object.assign([], store.getState().appState.gakkyu);
    return gakkyu.filter(g => g.gakurei === gakurei)
}

export function getStudentsByGakkyuId(gakkyuId) {
    const student = Object.assign([], store.getState().students.students);
    return student.filter(st => st.gakkyuId === gakkyuId)
}