import React from 'react';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { getStudentsByGakkyuId, store } from '../../../_helpers';
import moment from 'moment';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../Accessory/SnackBarContentWrapper";
import {switchSnackBarIsClose} from '../../../actions/AppState';
import {connect} from "react-redux";
import {buildSelectTimesFromAndTo} from "../../../_helpers/extendedDaycare";




const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
        textAlign: 'right',
    },
    rowHeader: {
        // backgroundColor: green[300],
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3),
        float: 'right',
    },
    formControl: {
        marginRight: theme.spacing(2),
    },
    commentArea: {
        minWidth: '90%',
        minHeight: '150px',
    },
}));


function AddExtendDaycareStudent(props) {
    const classes = useStyles();

    const extendedDaycareScheduleMonths = props.daySchedule.extendedDaycareScheduleMonths;


    const handleClose = function() {
        props.actions.switchSnackBarIsClose();
    };


    if (!props.appState.gakkyu[0]) {
        return (
            <React.Fragment>
                loading
            </React.Fragment>
        );
    } else {

        // const {fromSelection, toSelection} = buildSelectTimesFromAndTo(props.state.initialFromTime, props.state.initialToTime, props.state.fromTime);
        const {fromSelection, toSelection} = buildSelectTimesFromAndTo('07:00:00', '21:00:00', props.state.fromTime);


        return (
            <React.Fragment>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={store.getState().appState.snackBarIsOpen}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={handleClose}
                        variant="success"
                        className={classes.margin}
                        message={moment(props.state.date, 'YYYY-MM-DD').format('YYYY年MM月DD日(ddd)') + "の預かり保育情報を登録しました"}
                    />
                </Snackbar>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>預かる日</TableCell>
                            <TableCell colSpan={3}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        defaultValue={moment(props.state.date, 'YYYY-MM-DD').format('YYYY-MM')}
                                        onChange={e=>props.handleChange(e,'month')}
                                        input={
                                            <OutlinedInput name="month"  id="outlined-month-native-simple" />
                                        }
                                    >
                                        {extendedDaycareScheduleMonths.map(exd => {
                                            return (<option key={exd+'-monthSchedule'} value={exd}
                                                            selected={exd === moment(props.currentSelectedDate, 'YYYY-MM-DD').format('YYYY-MM')}>
                                                {moment(exd, 'YYYY-MM').format('YYYY年MM月')}
                                            </option>)
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        defaultValue={props.state.date}
                                        onChange={e=>props.handleChange(e,'date')}
                                        input={
                                            <OutlinedInput name="date"  id="outlined-date-native-simple" />
                                        }
                                    >
                                        {props.daySchedule.extendedDaycareSchedule.map(exd => {
                                            return (<option key={exd.id+'-dateSchedule'} value={exd.date}
                                                            selected={exd.date === props.currentSelectedDate}>
                                                {moment(exd.date, 'YYYY-MM-DD').format('DD日(ddd)')}
                                            </option>)
                                        })}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>学年・学級・氏名</TableCell>
                            <TableCell colSpan={3}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.gakkyuId}
                                        onChange={e=>props.handleChange(e,'gakkyuId')}
                                        input={
                                            <OutlinedInput name="gakkyu"  id="outlined-gakkyu-native-simple" />
                                        }
                                    >
                                        {props.appState.gakkyu.map(g => {
                                            return (<option key={g.id+'-gakkyuId'} value={g.id}>{g.name}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.studentId}
                                        onChange={e=>props.handleChange(e,'studentId')}
                                        input={
                                            <OutlinedInput name="studentId"  id="outlined-studentId-native-simple" />
                                        }
                                    >
                                        {getStudentsByGakkyuId(props.state.gakkyuId).map(st => {
                                            return (<option key={st.id+'-studentId'} value={st.id}>{st.nameKana}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>保育時間(何時から何時まで)</TableCell>
                            <TableCell colSpan={3}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.fromTime}
                                        onChange={e=>props.handleChange(e,'fromTime')}
                                        input={
                                            <OutlinedInput name="fromTime"  id="outlined-fromTime-native-simple" />
                                        }
                                    >
                                        {fromSelection.map(t => {
                                            return (<option key={'from-'+ t.format('HHmm') +'-time'} value={t.format('HH:mm')}>{t.format('HH:mm')}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.toTime}
                                        onChange={e=>props.handleChange(e,'toTime')}
                                        input={
                                            <OutlinedInput name="toTime"  id="outlined-toTime-native-simple" />
                                        }
                                    >
                                        {toSelection.map(t => {
                                            return (<option key={'to-'+ t.format('HHmm') +'-time'} value={t.format('HH:mm')}>{t.format('HH:mm')}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                オプション
                            </TableCell>
                            <TableCell>
                                <FormControlLabel
                                    control={<Checkbox checked={props.state.hasAteSnack} onChange={e=>props.handleChange(e,'hasAteSnack')} value={'hasAteSnack'} />}
                                    label='おやつ'
                                />
                                <FormControlLabel
                                    disabled={!props.state.enableHasJoinGymnastics}
                                    control={<Checkbox checked={props.state.hasJoinGymnastics} onChange={e=>props.handleChange(e,'hasJoinGymnastics')} value={'hasJoinGymnastics'} />}
                                    label='課外体育'
                                /><small>(課外体育に参加する場合は保育開始時刻に注意して下さい)</small>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>備考</TableCell>
                            <TableCell colSpan={3}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rows='5'
                                    rowsMax="20"
                                    value={props.state.comment}
                                    onChange={e=>props.handleChange(e,'comment')}
                                    className={classes.commentArea}
                                    margin="normal"
                                    // helperText="備考欄"
                                    variant="outlined"
                                />
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                <Button variant="contained" color="primary" className={classes.button} onClick={e=>props.registerExtendedDaycare(e)}>
                    預かり保育の予定を登録する
                </Button>
            </React.Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
        actions: {
            switchSnackBarIsClose: () => dispatch(switchSnackBarIsClose()),
        }
    })
)(AddExtendDaycareStudent);