import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//
// import clsx from 'clsx';
//
// import Table from '@material-ui/core/Table';
// import TableRow from '@material-ui/core/TableRow';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import Title from '../../../components/Text/Title';
// import Container from '@material-ui/core/Container';
// import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
// import moment from 'moment';
// import Icon from "../../../components/Accessory/Icon";
// import Button from "@material-ui/core/Button";
//

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  rowHeader: {
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  sidePaddingZero: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  listCell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  commentArea: {
    minWidth: '250px',
  },
  iconCell: {
    width: '30px',
  }
}));

export default function ManageDaycareRecords(props) {
  // const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  //
  // const [currentDay, setCurrentDay] = React.useState(moment());
  // const daycareRecords = props.extendedDaycare.extendedDaycareRecords;
  // const currentRecords = daycareRecords[currentDay.format('YYYY-MM-DD')] ? daycareRecords[currentDay.format('YYYY-MM-DD')]:[];
  //
  // const recordDelete = (e, row) => {
  //   props.deleteExtendedDaycareRecords(row.id);
  // };
  //
  // const recordChangeStatus = (e, row) => {
  //   props.changeStatusExtendedDaycareRecords(row.id, 'reservation');
  // };
  // return (
  //     <main className={props.contentClass}>
  //       <div className={props.toolbarClass}/>
  //       <Container maxWidth="lg" className={classes.sidePaddingZero}>
  //         <Grid container spacing={3}>
  //           <Grid item xs={12}>
  //             <Paper className={fixedHeightPaper}>
  //               <Title>{currentDay.format('YYYY年M月d日(ddd)')}の預かり保育</Title>
  //               <Title>申請人数 {currentRecords.length} 名</Title>
  //               <Table size="medium">
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell className={classes.listCell}>くみ</TableCell>
  //                     <TableCell className={classes.listCell}>なまえ</TableCell>
  //                     <TableCell className={classes.listCell}>何時から</TableCell>
  //                     <TableCell className={classes.listCell}>何時まで</TableCell>
  //                     <TableCell className={classes.commentArea}>備考</TableCell>
  //                     <TableCell></TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <TableBody>
  //                   {currentRecords.map(row => (
  //                       <TableRow key={row.id + '-extends'}>
  //                         <TableCell className={classes.listCell}>{row.gakkyuNameKana}</TableCell>
  //                         <TableCell className={classes.listCell}>{row.studentNameKana}</TableCell>
  //                         <TableCell className={classes.listCell}>{moment(row.fromTime,'HH:mm:ss').format('HH:mm')}</TableCell>
  //                         <TableCell className={classes.listCell}>{moment(row.toTime,'HH:mm:ss').format('HH:mm')}</TableCell>
  //                         <TableCell className={classes.commentArea}>{row.comment}</TableCell>
  //                         <TableCell className={classes.iconCell}><Button
  //                             aria-controls="customized-menu"
  //                             aria-haspopup="true"
  //                             color="default"
  //                             onClick={e => recordDelete(e, row)}
  //                         >
  //                           <Icon iconKey={'delete'}/>
  //                         </Button></TableCell>
  //                       </TableRow>
  //                   ))}
  //                 </TableBody>
  //               </Table>
  //             </Paper>
  //           </Grid>
  //         </Grid>
  //       </Container>
  //     </main>
  // );
}